<template>
  <div class="single-notification shadow-sm">
    <div class="upper-block">
      <div
        class="notification-block shadow-sm"
        :style="
          notification && notification.image[0]
            ? 'background:linear-gradient(to bottom, rgba(0,0,0,0.5) 100%, rgba(0,0,0,0.1) 100%),url(' +
              notification.image +
              ');'
            : 'background:#e72a37;'
        "
      >
        <div class="notification-text">
          <div class="notification-heading">
            {{ notification && notification.user ? notification.name : "..." }}
          </div>
          <div class="notification-name">
            {{ notification && notification.user ? notification.user : "..." }}
          </div>
        </div>
      </div>
    </div>
    <div class="lower-block">
      <div class="action-block">
        <div class="active-actions">
          <div class="row">
            <span class="edit action-btn" @click="editNotification(index)"
              >Edit</span
            >
            <span
              @click="takeAction"
              class="action-btn"
              :class="!archived ? 'archive' : 'unarchive'"
              >{{ !archived ? "Archive" : "Unarchive" }}</span
            >

            <span v-if="!archived" @click="sendPush" class="sent action-btn"
              >Send</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="info-block">
            <div class="info-head">Subheading</div>
          </div>
        </div>
        <div class="col-12">
          <div class="info-block">
            <div class="info-value shadow-sm">
              {{
                notification && notification.subHeading
                  ? truncateText(notification.subHeading, 100)
                  : "..."
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="info-block">
            <div class="info-head">Long Description</div>
            <div class="info-value shadow-sm description">
              {{
                notification && notification.subHeading
                  ? truncateText(notification.description, 200)
                  : "..."
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    archived: Boolean,
    notification: Object,
    index: Number
  },
  methods: {
    truncateText: function(text, maxLength) {
      let truncated = text;
      if (truncated.length > maxLength) {
        truncated = truncated.substr(0, maxLength) + "...";
      }
      return truncated;
    },
    takeAction() {
      this.$emit("archiveAction", this.notification._id);
    },
    sendPush() {
      this.$emit("sendPush", this.notification._id);
    },
    editNotification: function(index) {
      this.$emit("editNotification", index);
    }
  }
};
</script>

<style lang="scss" scoped>
.single-notification {
  width: 100%;
  background: #fff;
  border-radius: 15px;
  border-top-left-radius: 0;
  position: relative;
  margin: 10px auto;

  .upper-block {
    .notification-block {
      border-radius: 0 0 10px 10px;
      height: 150px;
      background-size: cover !important;
      background-position: center top !important;
      display: flex;
      align-items: center;
      .notification-text {
        padding: 15px;
        display: flex;
        flex-direction: column;
        .notification-heading {
          font-size: 36px;
          line-height: 0.75;
          color: #fff;
        }
        .notification-name {
          font-size: 28px;
          color: #fff;
        }
      }
    }
  }

  .lower-block {
    padding: 20px;
    .row {
      margin-bottom: 10px;
    }
    .info-block {
      display: flex;
      flex-direction: column;
      height: 100%;

      .info-head {
        font-weight: 600;
        margin-bottom: 5px;
      }
      .info-value {
        padding: 10px;
        font-size: 12px;
        border: 1px #eee solid;
        border-radius: 10px;
        width: 100%;
        &.description {
          min-height: 65px;
        }
      }
    }
    .action-block {
      .active-action {
        display: flex;
        align-items: center;
      }
      .archived-actions {
        display: flex;
        align-items: flex-start;
      }
      .action-btn {
        color: #fff;
        cursor: pointer;
        padding: 8px 25px;
        display: flex;
        margin-right: 5px;
        border-radius: 20px;
        &.edit {
          background: #44f56d;
          margin-left: auto;
        }
        &.archive {
          background: #f8b040;
        }

        &.sent,
        &.unarchive {
          background: #50a6fa;
        }
      }
    }
  }
}
</style>
