<template>
  <div>
    <b-modal
      id="new-notification-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-notification-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-notification-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Notification</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <form @submit="createNewNotificaition" @reset="resetForm">
        <div class="input-row row">
          <div class="col-md-6">
            <b-form-input
              required
              v-model="form.name"
              placeholder="Notificaion Header"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <div class="image-upload">
              <label for="notification-image-upload" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                  <span class="text">Upload Image</span>
                </span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="levelImage"
                id="notification-image-upload"
                class="mt-3"
                ref="notificationUploadRef"
              />
            </div>
          </div>
        </div>
        <div class="input-row">
          <span class="bold-label">Notification Type</span>
          <b-form-radio-group
            id="notification-type"
            v-model="form.userType"
            :options="notificationOptions"
            name="notification-type"
          ></b-form-radio-group>
        </div>
        <!-- Descriptions -->
        <div class="input-row row">
          <b-form-textarea
            id="textarea"
            v-model="form.subHeading"
            placeholder="Short Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div class="input-row row">
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            placeholder="Long Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div class="input-row row" v-if="status !== ''">
          <div class="col-12 text-center">
            {{ status }}
          </div>
        </div>
        <div class="input-row center">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Create
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
export default {
  data() {
    return {
      form: {
        name: "",
        subHeading: "",
        description: "",
        userType: "vendor"
      },
      notificationOptions: [
        { text: "Vendor", value: "vendor" },
        { text: "Rider", value: "rider" },
        { text: "Customer", value: "customer" }
      ],
      processingRequest: "",
      hasError: false,
      processingError: "",
      notificationImage: null,
      status: ""
    };
  },
  methods: {
    showModal: function() {
      this.$bvModal.show("new-notification-modal");
    },
    closeModal: function() {
      this.$bvModal.hide("new-notification-modal");
    },
    uploadFile: function() {
      this.notificationImage = this.$refs.notificationUploadRef.files[0];
    },
    resetForm: function() {
      const defaultForm = {
        name: "",
        subHeading: "",
        description: "",
        userType: "vendor"
      };
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
    },
    createNewNotificaition: function(e) {
      e.preventDefault();
      const tk = JwtService.getToken();
      this.form.token = tk;
      this.processingRequest = "processing";
      this.status = "Creating new Notification...";
      ApiService.post("/notifications/addNotification", this.form).then(res => {
        console.log("ApiService.post | res", res);
        if (res.data.success === 0) {
          this.hasError = "d-flex";
          this.processingError = res.data.message;
          this.processingRequest = "";
          this.status = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
          return;
        }
        if (res.data.success) {
          if (this.notificationImage !== null) {
            this.status = "Uploading and linking the image...";
            const newFormData = new FormData();
            newFormData.append("image", this.notificationImage);
            newFormData.append("notificationId", res.data.notification._id);
            newFormData.append("token", `${tk}`);
            ApiService.post("/notifications/uploadImage", newFormData).then(
              res => {
                this.status = "";
                if (res.data.success === 0) {
                  this.hasError = "d-flex";
                  this.processingError = res.data.message;
                  this.processingRequest = "";

                  setTimeout(() => {
                    this.hasError = "d-none";
                    this.processingError = "";
                  }, 3000);
                } else {
                  this.processingRequest = "";
                  this.$emit("addedNewNotification");
                }
              }
            );
          } else {
            this.status = "";
            this.processingRequest = "";
            this.$emit("addedNewNotification");
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
  .new-notification-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .input-row {
      padding: 10px 0;
      .form-control {
        border-radius: 0;
      }
      &.center {
        display: flex;
        justify-content: center;
        .btn-group {
          width: 100%;
          label.btn {
            &:nth-child(1) {
              margin-right: 5px;
            }
            &:nth-child(2) {
              margin-left: 5px;
            }
            border-radius: 0;
            background: #fff;
            padding: 20px 15px;
            &.active {
              color: #fff;
              background: #909090;
            }
          }
        }
      }

      .image-upload {
        label {
          display: flex;
          align-items: center;

          span.icon {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            &.attachment {
              display: flex;
              .text {
                max-width: 100px;
                text-align: center;
              }
            }
            i {
              font-size: 14px;
              color: #000;
              padding: 0 10px;
            }
            padding: 8px 5px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 10px;
            span.text {
              color: #808080;
            }
          }

          margin: 0;
          cursor: pointer;
        }
        input {
          display: none;
        }
      }
      .bv-no-focus-ring {
        display: flex;
        justify-content: space-around;
      }
      .bold-label {
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
</style>
